import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import Sprite from "../components/Sprite.vue";
import { computed } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'Icon',
    props: {
        name: { type: String, required: true },
        size: { type: Number, default: 16 },
        className: String,
    },
    setup(__props) {
        const props = __props;
        const SIZES = {
            16: 's',
            20: 'm',
            24: 'l',
            8: 'xs',
        };
        const className = computed(() => [
            `icon--${SIZES[props.size]}`,
            ...(props.className ? [props.className] : []),
        ]);
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("span", {
                class: _normalizeClass(["icon", className.value])
            }, [
                _createVNode(Sprite, {
                    name: __props.name,
                    size: __props.size
                }, null, 8 /* PROPS */, ["name", "size"])
            ], 2 /* CLASS */));
        };
    }
});
